import React from "react"
import { Layout } from '../components/Layout'
import { Header } from '../components/Header'
import { Section } from '../components/Section'

export default function ProductType({ pageContext: {products, type} }) {
  return (
    <>
      <Layout>
        <Header />
        <Section type={type} items={products}/>
      </Layout>
    </>
  )
}