import React from 'react';
import pluralize from 'pluralize';
import ProductElement from './ProductElement';

export const Section = ({ type, items }) => {
  const productElements = items.map((product) => (
    <ProductElement product={product} key={product.title} />
  ));

  return (
    <section className='max-w-screen-xl m-auto mb-12'>
      <h2 className='text-2xl text-center mb-8'>{pluralize(type)}</h2>
      <ul className='flex flex-wrap justify-center '>{productElements}</ul>
    </section>
  );
};
